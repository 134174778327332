export default {
   shared: {
      username: 'Gebruikersnaam',
      initials: 'Initialen',
      firstName: 'Voornaam',
      middleName: 'Tussenvoegsel',
      lastName: 'Achternaam',
      phoneNumber: 'Telefoonnummer',
      mobileNumber: 'Mobiel nummer',
      emailAddress: 'E-mailadres',
      address: 'Adres',
      characteristic: 'Kenmerk',
      amount: 'Bedrag',
      expirationDate: 'Vervaldatum',
      beneficiary: 'Begunstigde',
      sender: 'Verzender',
      receiver: 'Ontvanger',
      from: 'Van',
      to: 'Aan',
      subject: 'Onderwerp',
      CC: 'CC',
      BCC: 'BCC',
      content: 'Inhoud',
      attachments: 'Bijlagen',
      customer: 'Klant',
      batch: 'Batch',
      script: 'Script',
      scriptPlaceholder: 'Selecteer een script',
      template: 'Template',
      templatePlaceholder: 'Selecteer een template',
      templatePlaceholderNoneAvailable: 'Geen templates beschikbaar',
      contact: 'Contactpersoon',
      contactPlaceholder: 'Selecteer een contactpersoon',
      rightGroup: 'Rechtengroep',
      rightGroupPlaceholder: 'Selecteer een rechtengroep',
      all: 'Alle',
      filterFound: 'Er is een filter gevonden voor {filter}',
      search: 'Zoeken',
      errorSomethingWentWrong: 'Er is iets fout gegaan. Neem contact op met 10FORIT.',
      confirmButton: 'Bevestig',
      cancelButton: 'Annuleer',
      backButton: 'Terug',
      saveButton: 'Opslaan',
      editButton: 'Bewerk',
      deleteButton: 'Verwijder',
      fullscreenModeButton: 'Fullscreen modus',
      normalModeButton: 'Normale modus',
      dropBoxText: 'Drag & drop bestanden of klik hier om te uploaden',
      changeColumnSettings: 'Wijzig de kolom instellingen',
      resetColumns: 'Reset kolommen',
      description: 'Beschrijving',
      date: 'Datum',
      dateTime: 'Datum/tijd',
      type: 'Type',
      status: 'Status',
      name: 'Naam',
      fieldRequired: 'Dit veld is verplicht',
      contactList: 'Contactlijst',
      active: 'Actief',
      add: 'Toevoegen',
   },
   breadcrumbs: {
      batches: 'Batches',
      batchInfo: 'Batch info',
      scripts: 'Scripts',
      scriptInfo: 'Script Info',
      timeWindows: 'Script tijden',
      sendMessage: 'Stuur bericht',
      monitor: 'Live monitor',
      searchCase: 'Zoek case',
      fileUpload: 'Bestand uploaden',
      fileList: 'Bestanden lijst',
      contacts: 'Contacten',
      demoList: 'Demos',
      demo: 'Demo',
      whatsappweb: 'WhatsAppWeb',
      sms: 'Sms dashboard',
      mailReport: 'Mail rapportage',
      whatsAppReport: 'WhatsApp rapportage',
      whatsApp: 'Inkomende WhatsApp',
      paymentReport: 'Betaling rapportage',
      caseReport: 'Case rapportage',
      callReport: 'Gesprek rapportage',
      batchReport: 'Batch rapportage',
      batchMapping: 'Batch mapping',
      batchOutput: 'Batch output',
      scriptMapping: 'Script mapping',
      scriptOutput: 'Script output',
      blacklist: 'Blacklist',
      newCase: 'Nieuwe case',
      tts: 'TTS',
      manager: 'User manager',
      addUser: 'Add user',
      editRights: 'Edit rightgroup',
      wordcloud: 'Wordcloud',
      wallboard: 'Wallboard',
      account: 'Account instellingen',
   },
   maintenance: {
      title: 'Het dashboard is tijdelijk niet beschikbaar vanwege gepland onderhoud. Onze excuses voor het ongemak.',
      subtitle: 'Probeer het over een paar minuten opnieuw, of neem contact op met 10FORIT.',
   },
   login: {
      loginButton: 'Inloggen',
      password: 'Wachtwoord',
      forgotPasswordButton: 'Wachtwoord vergeten?',
      errorAccountLocked: 'Dit account is vergrendeld. Neem contact op met 10FORIT.',
      errorCredentialsEmpty: 'Gebruikersnaam en/of wachtwoord is leeg.',
      errorCredentialsIncorrect: 'Gebruikersnaam en/of wachtwoord is onjuist. Probeer opnieuw of neem contact op met 10FORIT.',
      errorPasswordExpired: 'Uw wachtwoord is verlopen. U kunt hieronder uw wachtwoord veranderen.',
   },
   forgotPassword: {
      title: 'Vul uw gebruikersnaam in om een e-mail te ontvangen met een wachtwoord reset link.',
      confirmMessage: 'Een e-mail met een wachtwoord reset link is naar het bijbehorende e-mailadres verstuurd als de gegeven gebruikersnaam bestaat',
      errorUsernameEmpty: 'Vul een gebruikersnaam in voordat u verder gaat',
   },
   changePassword: {
      title: 'Vul hieronder een nieuw wachtwoord in',
      newPassword: 'Nieuw wachtwoord',
      repeatPassword: 'Herhaal nieuw wachtwoord',
      changePasswordButton: 'Wijzig wachtwoord',
      generatePasswordButton: 'Genereer een willekeurig wachtwoord',
      characterRequired: 'Ten minste 1 letter',
      uppercaseRequired: 'Ten minste 1 hoofdletter',
      numberRequired: 'Ten minste 1 cijfer',
      lengthRequired: 'Ten minste 8 karakters lang',
      specialCharacterRequired: 'Ten minste 1 speciaal karakter',
      passwordWeak: 'zwak',
      passwordModerate: 'matig',
      passwordStrong: 'sterk',
      passwordVeryStrong: 'zeer sterk',
      noMatchingPasswords: 'wachtwoorden komen niet overeen',
      matchingPasswords: 'wachtwoorden komen overeen!',
      successMessage: 'Uw wachtwoord is nu veranderd. U kunt nu inloggen met uw nieuwe wachtwoord.',
      errorPasswordUsed: 'Dit wachtwoord is eerder gebruikt. Kies een ander wachtwoord.',
      errorOldPassword: 'Oude wachtwoord klopt niet voor deze gebruiker.',
      errorInvalidLink: 'Dit is geen geldige link. Vraag een nieuw wachtwoord reset link aan.',
   },
   toolbarLayout: {
      account: 'Account instellingen',
      logoutButton: 'Uitloggen',
      caseInfoToggleButton: 'Toggle case informatie',
   },
   activity: {
      amountAnswered: 'Aantal opgenomen',
      amountCalling: 'Aantal aan het bellen',
      amountTransferred: 'Aantal doorgeschakeld',
   },
   monitor: {
      resumeMonitoring: 'Hervat het monitor overzicht',
      pauseMonitoring: 'Pauzeer het monitor overzicht',
      noCases: 'Geen cases op dit moment.',
      inbound: 'Inkomend',
      human: 'Mens',
      voicemail: 'Voicemail',
      unknown: 'Onbekend',
      inCall: 'Opgenomen',
      transferredWith: 'Doorgeschakeld met',
      transferringTo: 'Doorschakelen naar',
      calling: 'Aan het bellen',
      cooldown: 'Cooldown',
   },
   monitorSettings: {
      callAttempts: 'Contact#',
      chosenPath: 'Gekozen pad',
      provider: 'Provider',
      lastUpdate: 'Laatste update',
   },
   searchCase: {
      searchDemoCases: 'Zoek in demo cases',
      searchCallsInBatchID: 'Zoek met CallsInBatchID',
      enableHistory: 'Gehele geschiedenis',
      noCasesFoundFor: "Geen cases gevonden voor '{searchTerm}'.",
      noCasesFound: 'Geen cases gevonden.',
      clickHere: 'Klik hier',
      toCreateCase: 'om een nieuwe case aan te maken.',
      caseStatus: 'Case status',
      open: 'Open',
      closed: 'Gesloten',
      closeCase: 'Sluit case',
      closeCaseConfirmMessage: 'Weet u zeker dat u de case wilt sluiten?',
      closeCaseModifyMessage: 'De resultaat code zal aangepast worden naar het volgende:',
      caseNumber: 'Dossiernummer',
      statusDateTime: 'Status datum/tijd',
      result: 'Resultaat',
      inbound: 'Inkomend',
      outbound: 'Uitgaand',
      copy: 'Kopieer',
      copyCase: 'Maak een nieuwe case door een kopie van deze case te maken',
   },
   advanceSearch: {
      alles: 'Alles',
      open: 'Open',
      gesloten: 'Gesloten',
      inkomend: 'Inkomend',
      uitgaand: 'Uitgaand',
      oproepstatus: 'Oproepstatus',
      oproeptype: 'Oproeptype',
      datum: 'Datum',
      resultaten: 'Resultaten',
      range: 'Datumbereik',
      reset: 'Reset',
   },
   caseInfo: {
      anonymizeButton: 'Anonimiseer deze case',
      anonymizeConfirmMessage: 'Weet u zeker dat u deze case wilt anonimiseren?',
   },
   editCase: {
      open: 'Case wijzigen',
      title: 'Case informatie wijzigen',
      emptyState: 'Deze case heeft geen velden die gewijzigd mogen worden',
   },
   caseDetails: {
      caseStatus: 'Case status',
      openCase: 'Open',
      pauseCase: 'Pauze',
      closeCase: 'Gesloten',
      batchID: 'Batch ID',
      lastUpdate: 'Laatste update',
      callAttempts: 'Belpogingen',
      lastAnswer: 'Laatste antwoord',
      lastResult: 'Laatste resultaat',
      linkedCase: 'Zie case:',
      paused: 'Gepauseerd tot',
      input: 'Invoer',
      scriptRunning: 'Het script loopt',
      calling: 'Aan het bellen',
      cooldown: 'Cooldown',
      transferred: 'Doorgeschakeld',
      beingTransferred: 'Aan het doorschakelen',
      transferring: 'Doorschakelen',
      closed: 'Gesloten',
      open: 'Open',
      human: 'Mens',
      voicemail: 'Voicemail',
      notAnswered: 'Niet opgenomen',
      openingCaseConfirmMessage: 'Weet u zeker dat u deze case opnieuw wilt openen?',
      closingCaseConfirmMessage: 'Weet u zeker dat u deze case wilt sluiten?',
   },
   caseComms: {
      downloadReportButton: 'Download het communicatie rapport',
      errorUnableToDownloadReport: 'Het downloaden van het communicatie rapport ging fout. Neem contact op met 10FORIT.',
      result: 'Resultaat',
      notAvailable: 'N.v.t.',
      notYetSent: 'Nog niet verstuurd',
      delivered: 'Afgeleverd',
      read: 'Gelezen',
      failed: 'Mislukt',
      errorMessage: 'Foutmelding',
      waiting: 'Wachtend',
      sent: 'Sent',
      received: 'Ontvangen',
      answered: 'Opgenomen',
      unanswered: 'Niet opgenomen',
      voicemail: 'Voicemail',
      inbound: 'Inkomend',
      transferred: 'Doorgeschakeld',
      callDuration: 'Gespreksduur',
      callDurationSeconds: '{x} seconden',
      call: 'Gesprek',
      ReportFileName: 'case_communicatie_rapport',
   },
   mailPreview: {
      dateTime: 'Datum/tijd',
   },
   whatsApp: {
      lastSeen: 'Laatst gezien',
      typeMessage: 'Typ een bericht',
      today: 'Vandaag',
      noResults: 'Geen berichten gevonden',
      openCase: 'Open bijbehorende case informatie',
      language: 'Taal',
      templateSelectorTitle: 'Beschikbare templates',
      selectedTemplateTitle: 'Geselecteerde template',
      closeBtn: 'Sluit',
      chooseBtn: 'Selecteer template',
      backBtn: 'Terug',
      sendTemplateBtn: 'Verstuur template',
   },
   PaymentHeader: {
      totalPayments: 'Aantal betalingen',
      totalAmount: 'Totaal bedrag',
   },
   PaymentOverview: {
      createButton: 'Maak een nieuwe betaal link aan',
      deleteButton: 'Verwijder deze betaal link',
      setPaidConfirmMessage: 'Weet u zeker dat u deze betaal link als betaald wilt markeren?',
      deleteConfirmMessage: 'Weet u zeker dat u deze betaal link wilt verwijderen?',
   },
   PaymentSelected: {
      longLink: 'Lange link',
      shortLink: 'Korte link',
      linkClicked: 'Link geklikt',
      websiteOpened: 'Bankwebsite geopend',
      paymentInProgress: 'Betaling bezig',
      paymentPaid: 'Betaald',
      paymentExpired: 'Vervallen',
      paymentCanceled: 'Geannuleerd',
      setPaidConfirmMessage: 'Weet u zeker dat u deze betaal link als betaald wilt markeren?',
      deleteConfirmMessage: 'Weet u zeker dat u deze betaal link wilt verwijderen?',
   },
   emptyState: {
      noMessagesSent: 'Er zijn (nog) geen betaal links verstuurd.',
      errorNoTemplatesAvailable: 'Er zijn geen betaling templates beschikbaar. Neem contact op met 10FORIT.',
   },
   AddPayment: {
      invoiceNumber: 'Factuurnummer',
      clientNumber: 'Klantnummer',
   },
   acceptEmailDashboard: {
      payment: 'Betaald',
      attempt: 'Betaalpoging',
      view: 'Betaling geopend',
      click: 'Link geklikt',
      noAcceptEmailsSent: 'Er zijn (nog) geen AcceptEmail berichten verstuurd.',
   },
   mailToPayDashboard: {
      lastModification: 'Last modification',
      noMessagesSent: 'Er zijn (nog) geen MailToPay berichten verstuurd.',
      unknown: 'Onbekend',
   },
   communications: {
      step: 'Stap',
      nextStepButton: 'Volgende stap',
      title: 'Selecteer op welke manier(en) u de klant wilt bereiken:',
      sendEmailOption: 'Verstuur een E-mail',
      sendSMSOption: 'Verstuur een SMS bericht',
      sendWhatsAppOption: 'Verstuur een WhatsApp bericht',
      sendMessageButton: 'Verstuur bericht',
      sendMessagesButton: 'Verstuur berichten',
      sendMessagesConfirmMessage: 'Weet u zeker dat u de geselecteerde persoon op de volgende manier(en) wilt bereiken?',
      options: 'Opties',
      errorNoWhatsAppTemplatesAvailable: 'Geen WhatsApp templates beschikbaar. Neem contact op met 10FORIT om WhatsApp berichten te kunnen versturen.',
      messageSuccessfullySend: 'Bericht succesvol verzonden.',
      multipleMessagesSuccessfullySend: 'Alle berichten succesvol verzonden.',
      emailFailedEmailAddressBlacklisted: 'De e-mail kon niet verzonden worden omdat het gegeven e-mailadres op de blacklist staat.',
      messagesSuccessfullySendExceptForEmailBlacklisted: 'Alle berichten succesvol verzonden, behalve de e-mail. Het gegeven e-mailadres stond op de blacklist.',
      messagesSuccessfullySendExceptForEmail: 'Alle berichten succesvol verzonden, behalve de e-mail. Er is een onbekend fout opgetreden. Neem contact op met 10FORIT.',
   },
   sendEmail: {
      title: 'Verstuur E-mail',
      resetTemplateButton: 'Reset template',
      addAttachmentButton: 'Voeg bijlage toe',
      callDate: 'Gespreksdatum',
      defaultTemplateContent: 'Geachte heer / mevrouw {recipientName} \n\n...\n\nMet vriendelijke groet,\n\n{senderName} \n{contactInfo}',
      errorOneOrMoreFieldsEmpty: 'Een of meerdere vereiste velden zijn leeg. Vul alle vereiste velden in om verder te gaan.',
      errorPaymentNotAvailable: 'Waarschuwing: deze klant heeft geen beschikbare betaal links. Deze e-mail zal geen manieren bevatten om te betalen.',
      errorEmailBlacklisted: 'Dit e-mailadres staat op de blacklist. Vul een ander e-mailadres in om verder te gaan.',
   },
   sendSMS: {
      title: 'Verstuur SMS bericht',
      resetTemplateButton: 'Reset template',
      switchDeviceButton: 'Weergave via ander apparaat',
      callDate: 'Gespreksdatum',
      defaultTemplateContent: 'Geachte heer / mevrouw {recipientName} \n\n...\n\nMet vriendelijke groet,\n\n{senderName} \n{contactInfo}',
      errorOneOrMoreFieldsEmpty: 'Een of meerdere vereiste velden zijn leeg. Vul alle vereiste velden in om verder te gaan.',
   },
   sendWhatsApp: {
      title: 'Verstuur WhatsApp bericht',
      resetTemplateButton: 'Reset template',
      switchDeviceButton: 'Weergave via ander apparaat',
      callDate: 'Gespreksdatum',
      errorOneOrMoreFieldsEmpty: 'Een of meerdere vereiste velden zijn leeg. Vul alle vereiste velden in om verder te gaan.',
   },
   batches: {
      permanently: 'Oneindig',
      batchOpen: 'Open',
      batchPaused: 'Pauze',
      batchClosed: 'Gesloten',
      casesDoneMsg: 'Voltooide cases: {amount}',
      casesTotalMsg: 'Totale cases: {amount}',
      batchSettingsSavedMessage: 'De batch instellingen zijn opgeslagen.',
      modifyBatchComment: 'Wijzig de opmerking van deze batch:',
      preparing: 'Voorbereiden',
      noBatchesFoundFor: "Geen batches gevonden voor '{searchTerm}'.",
      noBatchesFound: 'Geen batches gevonden.',
      clickHere: 'Klik hier',
      toImportBatch: 'om een batch bestand te uploaden en in te lezen.',
      openPausedCasesConfirmMessage: 'Wilt u ook alle gepauzeerde cases openen?',
      closeBatchConfirmMessage: 'Weet u zeker dat u deze batch en alle bijbehorende cases wilt sluiten?',
   },
   batchSettings: {
      batchID: 'Batch ID',
      priority: 'Prioriteit',
      numberRecognition: 'Nummerherkenning',
      dialer: 'Dialer aan',
      redial: 'Redial aan',
      casesProgress: 'Voortgang cases',
      casesTotal: 'Cases',
      casesOpen: 'Open',
      casesStarted: 'Start',
      importDateTime: 'Geïmporteerd',
      paused: 'Pauze',
      currentLines: 'Huidige lijnen',
      maximumLines: 'Maximale lijnen',
      transferLines: 'Doorgeschakelde lijnen',
      casesAnswered: 'Aantal opgenomen',
      casesCalled: 'Aantal aan het bellen',
      casesTransferred: 'Aantal doorgeschakeld',
      comments: 'Opmerkingen',
   },
   batchInfo: {
      lockOrUnlockBatch: 'Vergendel/ontgrendel batch',
      saveBatchButton: 'Opslaan',
      totalCases: 'Cases totaal',
      openCases: 'Cases open',
      closedCases: 'Cases gesloten',
      busyCases: 'Cases bezig',
      pausedCases: 'Cases gepauzeerd',
      notCalledCases: 'Cases niet gebeld',
      humanCases: 'Cases mens',
      voicemailCases: 'Cases voicemail',
      otherCases: 'Cases anders',
      sentEmails: 'E-mails verstuurd',
      sentSMSMessages: 'SMS berichten verstuurd',
      sentWhatsAppMessages: 'WhatsApp berichten verstuurd',
      sentPaymentMessages: 'Betaallinks aangemaakt',
      importDateTime: 'Datum/tijd geïmporteerd',
      enableDialer: 'Dialer aan',
      redial: 'Redial aan',
      numberRecognition: 'Nummerherkenning',
      changePriority: 'Wijzig prioriteit',
      changeStatus: 'Wijzig status',
      pausedUntil: 'Gepauzeerd tot',
      permanently: 'Oneindig',
      batchReport: 'Batch rapport',
      batchOpen: 'Open',
      batchPaused: 'Pauzeer',
      batchClosed: 'Sluit',
      status: 'Status',
      batchSettingsSavedMessage: 'Batch instellingen succesvol opgeslagen.',
      notCalled: 'Niet gebeld',
      attempts: 'poging(en)',
      resumeAllPausedCasesConfirmMessage: 'Wilt u ook alle gepauzeerde cases hervatten?',
      closingBatchWarningMessage: 'Waarschuwing: als u deze batch sluit, zullen u ook alle bijbehorende cases sluiten.',
      preparing: 'Voorbereiden',
   },
   scripts: {
      activeLines: 'Actieve lijnen',
      maximumLines: 'Maximale lijnen',
      maximumTransfers: 'Maximale doorschakelingen',
      standardCallerID: 'Standaard CallerID',
      noScriptsFoundFor: "Geen scripts gevonden voor '{searchTerm}'.",
   },
   scriptInfo: {
      maximumTransfers: 'Maximale aantal doorschakelingen',
      maximumTransfersDescription: 'Het maximale aantal doorschakelingen dat is toegestaan. Als deze waarde is bereikt worden er geen nieuwe uitbelpogingen gedaan.',
      currentNumberOfLines: 'Huidig aantal lijnen',
      currentNumberOfLinesDescription: 'Dit bepaalt de snelheid van de uitvoering van de batch. Hoe hoger het nummer, hoe sneller de batch is voltooid.',
      maximumLines: 'Maximum aantal cases tegelijk',
      maximumLinesDescription: 'Dit bepaalt de snelheid van de uitvoering van de batch. Hoe hoger het nummer, hoe sneller de batch is voltooid.',
      maximumLinesError: 'Aantal lijnen moet hoger zijn dan het maximaal aantal doorschakelingen',
      voice: 'Stem',
      language: 'Taal',
      standardCallerID: 'Standaard CallerID',
      none: 'Geen',
      voicemailDetection: 'Voicemail detectie',
      SMSProvider: 'SMS provider',
      timeWindows: 'Tijdvensters',
      extraTimeWindows: 'Extra tijdvensters',
      modifyTimeWindows: 'Wijzig de tijdvensters',
      holidays: 'Feestdagen',
      showHolidays: 'Toon de officiële feestdagen',
      scriptVariables: 'Script variabelen',
      resetDailyDescription: 'Deze optie stelt in dat de variabel elke dag gereset wordt naar de standaard waarde.',
      exampleFileButton: 'Download voorbeeld bestand',
      templateEditorButton: 'Templates bekijken',
      exampleFileName: 'voorbeeld',
      scriptSettingsSavedMessage: 'Script instellingen succesvol opgeslagen.',
      monday: 'Maandag',
      tuesday: 'Dinsdag',
      wednesday: 'Woensdag',
      thursday: 'Donderdag',
      friday: 'Vrijdag',
      saturday: 'Zaterdag',
      sunday: 'Zondag',
      value: 'Waarde',
      standardValue: 'Standaard waarde',
      resetDaily: 'Reset dagelijks',
      forever: '24/7 aan',
      foreverDescription: 'Wanneer deze optie wordt aangevinkt, dan zal het script altijd worden uitgevoerd, ook op feestdagen',
      foreverWarning: 'Let op! Wanneer deze optie wordt aangevinkt, dan zal het script altijd worden uitgevoerd, ook op feestdagen',
      usageWarning: 'In gebruik',
      usageWarningMsg: 'De variabele is al in gebruik in script: {script}',
   },
   timeWindow: {
      startTime: 'Begintijd',
      endTime: 'Eindtijd',
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
      earliestTime: 'Tijd kan niet ingesteld worden voor {min}:00',
      latestTime: 'Tijd kan niet ingesteld worden na {max}:59',
      deleteTimeWindowButton: 'Verwijder deze tijdvenster',
      lockOrUnlockSettings: 'Vergendel/ontgrendel instellingen',
      createTimeWindowButton: 'Voeg een tijdvenster toe',
      scriptsWithSameTimetable: 'Scripts met hetzelfde tijdschema',
      callingTimes: 'Beltijden',
      timeWindowSettingsSavedMessage: 'Tijdvenster instellingen succesvol opgeslagen.',
      errorInvalidFields: 'Tijdvenster instellingen niet opgeslagen: een of meerdere velden bevatten niet toegstaande waarden.',
   },
   editorContainer: {
      email: 'E-mail',
      SMS: 'SMS',
      whatsApp: 'WhatsApp',
   },
   templateEditor: {
      mobileViewDescription: 'Zet kolommen naast elkaar op mobiel schermformaat',
      mobileView: 'Telefoon weergave',
      changeWarningHint: 'Openstaande wijzingen eerst opslaan of verwijderen',
      changeWarningHeader: 'Template verlaten?',
      changeWarningText: 'Weet u zeker dat u deze template wilt verlaten? Alle niet opgeslagen aanpassingen gaan verloren',
      noRepeat: 'Niet herhalen',
      repeat: 'Herhalen',
      fullWidth: 'Volledige breedte',
      backgroundWidth: 'Achtergrond breedte',
      standardWidth: 'Standaard breedte',
      imageSize: 'Afbeelding grootte',
      backgroundPositionY: 'Verticale positie afbeelding',
      backgroundPositionX: 'Horizontale positie afbeelding',
      backgroundImage: 'Achtergrond afbeelding',
      addSrc: 'Voeg afbeelding toe van apparaat',
      addLink: 'Voeg link toe',
      files: 'bestanden',
      requiredFileType: 'Wij accepteren alleen: {files}',
      pasteLink: 'Plak de link in de tekstvak',
      addImage: 'Voeg afbeelding toe',
      discard: 'Annuleren',
      emptyScript: 'Op dit moment zijn er geen templates.',
      addTemplate: 'Klik op een script en voeg een template toe om te beginnen!',
      discardAllChanges: 'Verwijder alle wijzigingen',
      advancedOptionsStateOn: '(aan)',
      advancedOptionsStateOff: '(uit)',
      advancedOptions: 'Geavanceerde opties',
      headerOptions: 'Header opties',
      inlineStyle: 'Inline weergave',
      styleDivider: 'Stijl verdeler',
      letterSpacing: 'Letterafstand',
      textTransform: 'Hoofdlettergevoeligheid',
      thumbnailStyle: 'Thumbnail stijl',
      fontStyle: 'Lettertype stijl',
      textDecoration: 'Tekst decoratie',
      fontWeight: 'Lettertype dikte',
      fontSize: 'Lettertype grootte',
      paddingIcon: 'Vulling icoon',
      textAlignment: 'Tekst positie',
      borderWidth: 'Rand dikte',
      borderLeft: 'Rand links',
      borderRight: 'Rand rechts',
      borderTop: 'Rand boven',
      borderBottom: 'Rand onder',
      borderColor: 'Rand kleur',
      borderStyle: 'Rand stijl',
      styleText: 'Tekst stijl',
      iconWidth: 'Icoon breedte',
      iconPosition: 'Icoon positie',
      tumbnailWidth: 'Tumbnail breedte',
      tumbnailVisibilty: 'Tumbnail zichtbaarheid',
      altText: 'Alternatief text',
      iconStyling: 'Icoon styling (hamburger)',
      innerPadding: 'Padding binnenkant',
      iconHeight: 'Icoon hoogte',
      position: 'Positie',
      title: 'Titel',
      buttonWidth: 'Button breedte',
      buttonHeight: 'Button hoogte',
      textPosition: 'Tekst Positie',
      text: 'tekst',
      elementHeight: 'Element hoogte',
      verticalPosition: 'Verticale positie',
      backgroundColorButton: 'Achtergrond kleur knop',
      cardTitle: 'Card titel',
      cardDescription: 'Card beschrijving',
      cardURL: 'Card URL',
      saveTemplateButton: 'Template opslaan',
      reloadTemplateButton: 'Herlaad template',
      sendDemoButton: 'Verstuur demo',
      saved: 'De template is succesvol opgeslagen',
      saveSettingsConfirmMessage: 'Weet u zeker dat u uw wijzigingen wilt opslaan?',
      unknownValue: 'Onbekende waarde',
      demoValue: 'Demo waarde',
      enableTracking: 'Tracking inschakelen',
      chooseTemplate: 'Selecteer een template',
      variableTemplateName: 'Template naam',
      templateCreatedSuccessfully: 'De template is succesvol aangemaakt.',
      templateInformationModifiedSuccessfully: 'De informatie van de template is succesful aangepast.',
      demoSendSuccessfully: 'De demo is succesvol verstuurd.',
      createTemplateButton: 'Nieuwe template',
      modifyDefaultTemplatesButton: 'Bewerk standaard templates',
      modifySectionTemplatesButton: 'Bewerk onderdeeltemplates',
      returnToDashboardButtonTooltip: 'Keer terug naar het dashboard',
      newTemplate: 'Nieuwe Template',
      defaultPlaintext: 'Voer hier de tekst in die uw wilt versturen.',
      revisions: 'Revisies:',
      templateName: 'Template naam',
      templateDescription: 'Template beschrijving',
      templatePreview: 'Kies startsjabloon',
      addOption: 'Nog een instelling toevoegen',
      options: {
         reference: 'Koppeling oude template',
         dashboard: 'Gebruik als dashboard template',
         from: 'Naam van verzender',
         formal: 'Formele aanhef',
         subject: 'Onderwerp van e-mail',
         to: 'Ontvanger',
         bcc: 'BCC ontvanger(s)',
         cc: 'CC ontvanger(s)',
         save_mime: 'Email opslaan',
         euro_symbol: 'Voeg euro symbool toe',
         tracking: 'Tracking aanzetten',
         always_send_if_cc: 'Altijd email versturen bij CC',
         log_status_datetime: 'Log status updates',
         header_size: 'Header breedte wijzigen',
         host: 'SMTP host',
         smtp_username: 'SMTP gebruiker',
         smtp_password: 'SMTP wachtwoord',
         priority: 'Prioriteit',
         no_eur: 'Geen EUR prefix toevoegen',
         sms_auto_long: 'Automatisch lange variant SMS gebruiken',
         sms_hlr: 'HLR aanzetten',
         sms_blacklist: 'Blacklist aanzetten',
         sms_server: 'SMS Server',
         type: 'Type',
         groupid: 'Groep ID',
         provider: 'Provider ID',
         card_url: 'Kaart URL',
         card_image: 'Kaart Afbeelding',
         card_title: 'Kaart Titel',
         card_description: 'Kaart Omschrijving',
      },
      recipient: 'Ontvanger',
      variableName: 'Variabel naam:',
      variableExample: 'Voorbeeld:',
      variablesMenuText: 'Variabelen',
      datesMenuText: 'Datums',
      contactMenuText: 'Contact',
      scriptMenuText: 'Script',
      dateTime: 'Datum tijd',
      shortDate: 'Korte datum',
      longDate: 'Lange datum',
      year: 'Jaar',
      nextYear: 'Volgend Jaar',
      lastYear: 'Vorig Jaar',
      time: 'Tijd',
      nextWeek: 'Volgende week',
      contactName: 'Contact naam',
      contactEmail: 'Contact e-mailadres',
      contactPhone: 'Contact telefoonnummer',
      columnsComponentsCategory: 'Kolommen',
      defaultComponentsCategory: 'Standaard componenten',
      spacingComponentsCategory: 'Tussenruimte',
      sectionTemplatesCategory: 'Onderdeeltemplates',
      sectionComponent: 'Sectie',
      styleComponent: 'Style (css)',
      columnComponent: 'Kolom',
      textComponent: 'Tekst',
      imageComponent: 'Afbeelding',
      buttonComponent: 'Knop',
      dividerComponent: 'Verdeler',
      spacerComponent: 'Splitser',
      variableCopy: 'Variabele is gekopieerd naar het klembord, je kunt hem nu plakken waar je hem nodig hebt',
      legend: 'Legenda',
      elements: 'Elementen',
      element: 'Element',
      template: 'Template',
      sections: 'Secties',
      section: 'Sectie',
      addSection: 'Sectie Toevoegen',
      bodyComponent: 'Body',
      widthElement: 'Breedte',
      heightElement: 'Hoogte',
      backgroundColor: 'Achtergrond kleur',
      backToDefaults: 'Terug naar standaardwaarden',
      border: 'Rand',
      borderInside: 'Rand Binnen',
      borderOutside: 'Rand Buitenkant',
      borderHorizontal: 'Rand Horizontale delen',
      borderSolid: 'Normaal',
      borderDotted: 'Puntjes',
      borderDashed: 'Gestreepd',
      borderDouble: 'Dubbel',
      deleteString: 'Verwijder: {msg}',
      addString: 'Toevoegen: {msg}',
      changeString: 'Aanpassen: {msg}',
      copyString: 'Kopieer: {msg}',
      optionsString: 'Opties: {msg}',
      allSides: 'Alle Kanten',
      top: 'Boven',
      bottom: 'Onder',
      left: 'Links',
      right: 'Rechts',
      textColor: 'Tekst kleur',
      labelFontFamily: 'Lettertype',
      labelCapitalization: 'Hoofdlettergevoeligheid',
      labelNone: 'Standaard',
      labelLowercase: 'kleine letters',
      labelUppercase: 'HOOFDLETTERS',
      labelCapitalize: 'Eerste Letter Met Hoofdletter',
      heroComponent: 'Hero',
      navbarComponent: 'Menu',
      carouselComponent: 'Fotogalerij',
      accordionComponent: 'Accordion',
      socialMediaComponent: 'Social Media',
      marginElement: 'Marge',
      paddingElement: 'Vulling',
      cssClass: 'CSS class',
      lineHeight: 'Lijn Hoogte',
      hrefString: 'Omleiding',
      srcImage: 'Afbeelding bron',
      borderRadius: 'Hoekradius',
      borderRadiusThumbnails: 'Hoekradius thumbnails',
      iconColor: 'Kleur Icoon',
      alignmentElement: 'Positie',
      iconSize: 'Icoongrootte',
      iconString: 'Icoon',
      borderThumbnail: 'Rand Miniatuur',
      borderColorThumbnail: 'Randkleur Miniatuur ({msg})',
      onHover: 'bij zweven',
      onSelected: 'geselecteerd',
      titleString: 'Titel',
      selectedMedia: 'Geselecteerde Media',
      orientationMode: 'Oriëntatie',
      marginText: 'Tekst Marge',
      warningDeleteImage: 'Weet u zeker dat u dit bestand wilt verwijderen? Dit heeft ook invloed op andere templates.',
      selectImage: 'Afbeelding Selecteren',
      targetHref: 'Locatie Omleiding',
      horizontalString: 'Horizontaal',
      verticalString: 'Verticaal',
      headingComponent: 'Koptekst',
      tableComponent: 'Tabel',
      targetBlank: 'Nieuw Tabblad',
      targetParent: 'Huidig Tabblad',
      targetSelf: 'Binnen E-mail',
      closeString: 'Sluiten',
      previewExample: 'Voorbeeld',
      gallery: 'Template galerij',
      optionsBtn: 'Wijzig Instellingen',
      saveBtn: 'Template Opslaan',
      copyBtn: 'Template Kopiëren',
      undoBtn: 'Ongedaan maken',
      redoBtn: 'Opnieuw doen',
      activateTemplate: 'Activeer de nieuwe wijzigingen',
      activateTemplateHint: 'Dit betekent dat het direct wordt toegepast op het bijbehorende script',
      activateParents: 'Wijzigingen toepassen op alle templates',
      activateParentsHint: 'Met deze optie worden alle templates gewijzigd die gebruikt maken van deze onderdeel template',
      deleteTemplate: 'Template Verwijderen',
      reloadTemplate: 'Template Herladen',
      deleteTemplateConfirm: 'Weet u zeker dat u de geselecteerde template wilt verwijderen? Hiermee worden ook alle revisies verwijderd',
      deleteVersionConfirm: 'Weet u zeker dat u de template versie wilt verwijderen?',
      activateVersionConfirm: 'Weet u zeker dat u de template versie wilt activeren? Dit zal direct worden toegepast op het script.',
   },
   files: {
      batches: 'Batches',
      reports: 'Rapporten',
      results: 'Resultaten',
      importSuccessful: 'Succesvol ingelezen',
      importFailed: 'Inlezen mislukt',
      importButton: 'Lees het bestand in',
      fileName: 'Bestandsnaam',
      batch: 'Batch',
      importDateTime: 'Geïmporteerd',
      uploadDateTime: 'Geüpload',
      fileSize: 'Bytes',
      errorDownloadFailed: 'Kon bestand ({file}) niet downloaden.',
      removeFileConfirmMessage: 'Weet u zeker dat u dit bestand wilt verwijderen?',
      downloadFileButton: 'Download bestand',
      deleteFileButton: 'Verwijder bestand',
   },
   fileUpload: {
      removeFileButton: 'Verwijder',
      toFileListButton: 'Naar de file list',
      importButton: 'Importeer bestanden',
      cancelUploadButton: 'Annuleer upload',
      confirmCancelUploadMessage: 'Weet u zeker dat u de upload wilt annuleren?',
      errorFileExceedsMaximumSize: 'Dit bestand is te groot om geüpload te worden (maximaal 10MB).',
      errorInvalidFileType: 'Dit bestandstype wordt niet ondersteund. Neem contact op met 10FORIT.',
      errorUploadFailed: 'Er ging iets verkeerd met het uploaden van de bestanden. Neem contact op met 10FORIT.',
   },
   contactView: {
      contacts: 'Contacten',
      contactLists: 'Contactlijsten',
   },
   contacts: {
      createContactButton: 'Maak een nieuw contactpersoon aan',
      deleteContactConfirmation: "Weet u zeker dat u contactpersoon '{contact}' wilt verwijderen?",
      errorActiveLoginAccount: 'Dit contactpersoon kan niet verwijderd worden, omdat er nog een login account is aan gelinkt.',
      editContactButton: 'Bewerk contactpersoon',
      deleteContactButton: 'Verwijder contactpersoon',
   },
   saveContact: {
      createContactButton: 'Contactpersoon aanmaken',
      editContactButton: 'Contactpersoon bewerken',
      saveContactButton: 'Contactpersoon opslaan',
   },
   contactList: {
      addContactListButton: 'Maak een contactlijst aan',
      memberAmount: 'Leden',
      description: 'Beschrijving',
   },
   addList: {
      addListButton: 'Lijst toevoegen',
      list: 'Lijst',
   },
   SMS: {
      title: 'SMS overzicht',
      temporarilyPaused: 'Tijdelijk gepauzeerd',
      permanentlyPaused: 'Permanent gepauzeerd',
      notPaused: 'Niet gepauzeerd',
      pauseEndTime: 'Tot {hour} uur',
      nextSixtyMinutes: 'Komende 60 minuten',
      amountPerHour: 'Aantal per uur',
   },
   showSMS: {
      pauseAll: 'Pauzeer alles',
      pauseAllButton: 'Pauze',
      startNow: 'Start nu',
      startNowButton: 'Start',
      endTime: 'Eindtijd',
      splitButton: 'Herverdeel',
      maximum: 'Maximum',
      settingsSavedMessage: 'SMS instellingen zijn succesvol opgeslagen.',
   },
   blacklist: {
      phoneNumberAndEmailAddress: 'Telefoonnummer/e-mailadres',
      downloadBlacklistButton: 'Download de blacklist',
      dateTimeAdded: 'Datum/tijd toegevoegd',
      errorBlacklistEmpty: 'De blacklist kon niet gedownload worden, omdat deze geen items bevat.',
      addPhoneNumberOrEmailAddressButton: 'Voeg telefoonnummer/e-mailadres toe',
      removePhoneNumberOrEmailAddressButton: 'Verwijder telefoonnummer/e-mailadres',
      reason: 'Reden',
      valueSuccessfullyAdded: 'Telefoonnummer/e-mailadres is succesvol toegevoegd aan de blacklist.',
      errorValueEmpty: 'Vul een telefoonnummer/e-mailadres in om deze toe te voegen aan de blacklist.',
      errorInvalidValue: 'Dit is geen geldig telefoonnummer/e-mailadres.',
      errorDuplicateValue: ' staat al in de blacklist.',
      manualBlacklist: 'Handmatige blacklist',
      unsubscribers: 'Unsubscribers',
      saveItemFor: 'Item opslaan voor:',
      threeMonths: '3 maanden',
      sixMonths: '6 maanden',
      oneYear: '1 jaar',
      twoYears: '2 jaar',
      forever: 'Altijd',
   },
   resultReports: {
      title: 'Stap 1: Selecteer een of meer batches',
      importDateTime: 'Geïmporteerd',
      closedDateTime: 'Gesloten',
      nextStepButton: 'Volgende stap',
   },
   resultMapping: {
      title: 'Stap 2: Selecteer de gewenste kolommen',
      inputColumn: 'Kolomnaam aangeleverd',
      reportColumn: 'Kolomnaam rapport',
      exampleColumn: 'Voorbeeld',
      endResult: 'Eind resultaat',
      endDateTime: 'Eind datum/tijd',
      time: 'Tijd',
      chosenPath: 'Gekozen pad',
      callAttempts: 'Belpogingen',
      waitingTime: 'Wachttijd',
      talkTime: 'Gesprekstijd',
      emailResult: 'E-mail resultaat',
      SMSResult: 'SMS resultaat',
      resultCode: 'DOORVERBONDEN',
      callStatus: 'Status gesprek',
      sampleCallStatus: 'Opgenomen',
      intermediateResult: 'Tussenresultaat',
      intermediateDateTime: 'Tussen datum/tijd',
      period: 'Periode',
      nextStepButton: 'Volgende stap',
   },
   resultOutput: {
      title: 'Stap 3: Kies de gewenste uitvoer',
      screen: 'Scherm',
      CSV: 'CSV',
      excel: 'Excel',
      selectedColumns: 'Geselecteerde kolommen',
      noColumnsSelected: 'Geen kolommen geselecteerd',
      start: 'Start',
      scriptReport: 'Script rapport',
      batchReport: 'Batch rapport',
      type: 'Type',
      period: 'Periode',
      noRange: 'Geen specifieke periode',
   },
   caseReports: {
      downloadReportButton: 'Download rapportage als PDF',
      thisMonth: 'Deze maand',
      today: 'Vandaag',
      timeline: 'Tijdlijn',
      caseReportFileName: 'case_rapportage',
      createCaseReportButton: 'Maak een case rapport over de gekozen periode',
   },
   mailReports: {
      sent: 'Verzonden',
      failed: 'Mislukt',
      tracked: 'Getracked',
      read: 'Gelezen',
      percentageLabel: 'Percentages e-mails',
      emailAmount: 'Hoeveelheid emails',
      emailsSent: 'Verzonden e-mails',
      emailsFailed: 'Mislukte e-mails',
      emailsReadAndTracked: 'Gelezen / getrackte e-mails',
      timeline: 'Tijdlijn',
      topSenders: 'Top verzenders',
      percentage: 'Percentages',
      topErrors: 'Top foutmeldingen',
      noResults: 'Geen resultaten!',
      errorMessage: 'Foutmelding',
      mailReportFileName: 'mail_rapportage',
   },
   paymentReports: {
      amountPaid: 'Hoeveel betaald',
      amountClick: 'Hoeveel geklikt',
      hour: 'Uur',
      amount: 'Hoeveelheid',
      paymentsOpen: 'Openstaande betalingen',
      paymentsPaid: 'Betalingen betaald',
      paymentsEstimated: 'Verwachte betalingen',
      paymentsCancelled: 'Geannuleerde betalingen',
      noResults: 'Geen resultaten!',
      timeline: 'Tijdlijn',
      paymentsStatus: 'Status betalingen',
      amountPerHour: 'Hoeveelheid per uur',
      amountsStatus: 'Status hoeveelheden',
      fileName: 'betaling_rapportage',
      reference: 'Kenmerk',
   },
   whatsAppReports: {
      read: 'Gelezen',
      sent: 'Verzonden',
      failed: 'Mislukt',
      amountLabel: 'WhatsApp hoeveelheid',
      percentageLabel: 'WhatsApp percentages',
      messagesSent: 'Verzonden WhatsApp',
      messagesDelivered: 'Afgeleverde WhatsApp',
      messagesFailed: 'Mislukte WhatsApp',
      messagesRead: 'Gelezen WhatsApp',
      timeline: 'Tijdlijn',
      topSenders: 'Top verzenders',
      percentage: 'Percentages',
      noResults: 'Geen resultaten!',
      errorMessage: 'Foutmelding',
      error: 'Geblokkeerd',
      to: 'Naar',
      whatsAppReportFileName: 'whatsapp_rapportage',
   },
   callReports: {
      teamFilter: 'Teams',
      queueFilter: 'Wachtrijen',
      dateFilter: 'Datum',
      ahtPerformance: 'AHT/ASA performance interval',
      agents: 'Medewerkers',
      filterAgents: 'Medewerkers filteren',
      minute: 'Kwartier',
      hour: 'Uur',
      day: 'Dag',
      week: 'Week',
      month: 'Maand',
      seconds: 'Secondes',
      percentage: 'Percentage',
      calls: 'Gesprekken',
      total: 'Totaal',
      agent: 'Medewerker',
      id: 'ID',
      aht: 'AHT',
      att: 'ATT',
      acw: 'ACW',
      hold: 'Hold',
      cpu: 'CPU',
      answered: 'Aangenomen calls',
      rona: 'RONA\'s',
      available: 'Beschikbaar',
      unavailable: 'Afwezig',
      dnd: 'Niet storen',
      overtime: 'Nawerktijd',
      pause: 'Pauze',
      offeredCalls: 'Aangeboden calls',
      acceptedCalls: 'Aangenomen calls',
      availability: 'Bereikbaarheid',
      startDate: 'Begindatum',
      endDate: 'Einddatum',
   },
   wordcloud: {
      optionsButton: 'Opties',
      errorNoDataAvailable: 'Geen wordcloud data beschikbaar. Neem contact op met 10FORIT.',
   },
   wordcloudMenu: {
      filters: 'Filters',
      words: 'Woorden',
      startDate: 'Begindatum',
      endDate: 'Einddatum',
      source: 'Bron',
      target: 'Ontvanger',
      deleteWordButton: 'Verwijder woord',
   },
   newCase: {
      optionalFields: 'Optionele velden:',
      requiredFields: 'Verplichte velden:',
      oneRequiredField: 'Eén van de volgende velden is verplicht:',
      nextButton: 'Volgende',
      errorNoScriptsFound: 'Geen scripts beschikbaar. Neem contact op met 10FORIT.',
      createCaseButton: 'Maak case',
   },
   wallboard: {
      longestWaiting: 'Langst wachtende',
      amountWaiting: 'Wachtend',
      amountUnanswered: 'Onbeantwoord',
      amountDisconnected: 'Afgebroken',
      amountAnswered: 'Aangenomen',
      queueName: 'Queue naam',
      queueNumber: 'Queue nummer',
      caller: 'Beller',
      loginTime: 'Login tijd',
      statusTime: 'Status tijd',
      status: 'Status',
      statusEnum: {
         Calling: 'In gesprek',
         Cooldown: 'Cooldown',
         Away: 'Afwezig',
         Available: 'Beschikbaar',
         'Out of office': 'Niet storen',
         'Custom 1': 'Pauze',
         'Custom 2': 'Nawerktijd',
      },
      agent: 'Medewerker',
      overview: 'Queue overzicht',
      liveAgents: 'Live Medewerkers',
      busyAgents: 'Agents in gesprek',
      loggedInAgents: 'Agents ingelogd',
      averageWaitingTime: 'Gemiddelde wachttijd',
      agentStatus: 'Agents in gesprek / Agents ingelogd',
      availability: 'Bereikbaarheid',
      serviceLevel: 'Service level',
      averageHandlingTime: 'AHT',
      errorNoDataFound: 'Geen wallboard data beschikbaar. Neem contact op met 10FORIT.',
      downloadExcel: 'Download Excel bestand',
      multiviewReportFileName: 'Wallboard Rapportage',
   },
   users: {
      lockOrUnlockAccountButton: 'Vergrendel/ontgrendel account',
      deleteAccountButton: 'Verwijder account',
      deleteConfirm: 'Weet je zeker dat je dit account wilt verwijderen?',
      tokenConfirm: 'Weet je zeker dat je de API sleutel wilt resetten? Toegang met de oude sleutel komt gelijk te vervallen',
      resetConfirm: 'Er is een link voor het opnieuw instellen van het wachtwoord verzonden naar: ',
      resetFail: 'Link voor opnieuw instellen van wachtwoord is mislukt. Neem contact op met ons support team',
      sendPasswordResetLink: 'Verstuur een wachtwoord reset link',
      createAccount: 'Gebruiker aanmaken',
      editRights: 'Rechtengroep wijzigen',
      editContact: 'Contact wijzigen',
      changeRights: 'Rechtengroep wijzigen',
      selectLanguage: 'Selecteer de taal van de e-mail voor het opnieuw instellen van het wachtwoord:',
      sendEmail: 'Verstuur e-mail',
      loginHeader: 'Gebruikersnaam',
      nameHeader: 'Volledige naam',
      emailHeader: 'E-mail adres',
      mobileHeader: 'Mobiele nummer',
      lastLoginHeader: 'Laatste login',
      rightGroupHeader: 'Rechtengroep(en)',
      tokenHeader: 'API sleutel',
      tokenCopy: 'De API sleutel is gekopieerd naar je klembord',
      engLang: 'Engels',
      nlLang: 'Nederlands',
      frLang: 'Frans',
   },
   addUser: {
      subtitle: 'Nieuwe gebruiker aanmaken:',
      userExists: 'Gebruikersnaam is al in gebruik.',
      userInvite: 'Er is een email uitnodiging verstuurd naar de nieuwe gebruiker',
      username: 'Gebruikersnaam',
      createAccount: 'Gebruiker aanmaken',
   },
   changeRight: {
      rightGroupHeader: 'Rechtengroep(en)',
      deleteConfirm: 'Account succesvol verwijderd uit de rechtengroep',
      deleteFail: 'Kan account niet verwijderen uit de rechtengroep',
      addConfirm: 'Account succesvol toegevoegd aan de rechtengroep',
      addFail: 'Kan account niet toevoegen aan de rechtengroep',
   },
   editRightGroup: {
      deleteRightObjectType: 'Verwijder rightobjecttype',
      deleteRightObject: 'Verwijder rightobject',
      saveRightObjectType: 'Rightobjecttype opslaan',
      saveRightObject: 'Rightobject opslaan',
   },
   rightGroup: {
      deleteRightGroup: 'Verwijder rightgroup',
   },
   feedback: {
      tooltip: 'Verstuur feedback',
      title: 'Mocht u nog tips of feedback hebben, laat het dan hieronder weten!',
      subTitle: '(Er word een screenshot meegestuurd van het huidige scherm)',
      description: 'Omschrijving',
      cancel: 'Sluit',
      send: 'Verstuur',
      save: 'Bedankt voor uw tijd en feedback! We nemen dit mee in ons proces en we zullen indien gewenst nog contact met u hierover opnemen.',
   },
   menu: {
      monitor: 'Monitor',
      search: 'Zoek/Sluit Case',
      batches: 'Batches',
      scripts: 'Scripts',
      files: 'Files',
      list: 'File Lijst',
      upload: 'Upload File',
      contacts: 'Contacten',
      sms: 'SMS',
      blacklist: 'Blacklist',
      reports: 'Rapportages',
      batchReport: 'Batches',
      caseReport: 'Cases',
      callReport: 'Gesprekken',
      paymentReport: 'Betalingen',
      mailReport: 'Mail',
      whatsappReport: 'WhatsApp',
      whatsapp: 'WhatsApp',
      wordcloud: 'Wordcloud',
      adhoc: 'Nieuwe Case',
      wallboard: 'Wallboard',
      admin: 'Admin tools',
      demo: 'Demo',
      tts: 'TTS',
      whatsappweb: 'WhatsAppWeb',
      manager: 'User Manager',
      templateEditor: 'Template Editor',
   },
   caseHeader: {
      startDemo: 'Start demo',
   },
   caseMenu: {
      details: 'Case Details',
      comms: 'Communicatie',
      payment: 'Betaling',
      acceptEmail: 'AcceptEmail',
      mailToPay: 'MailToPay',
      sendMessage: 'Stuur Bericht',
   },
   modifyCaseModal: {
      callStatusText: 'Zet de status van de case op:',
      resultCodeText: 'Zet de resultaat code van de case op:',
      confirmText: 'Weet u zeker dat u de case wilt aanpassen?',
      pauseDate: 'Pauzeer de geselecteerde case tot:',
   },
   account: {
      twoFactor: {
         enforce: 'Twee-factor authenticatie is verplicht binnen uw organisatie, voeg een methode toe:',
         tab: 'Beveiliging',
         option: 'Twee-factor Authenticatie',
         verify: 'Voer de Twee-factor code in:',
         remove: 'Verwijder Twee-factor methode:',
         error: 'Twee-factor codeverificatie mislukt. Probeer het opnieuw.',
         sendCode: 'Authenticatiecode verzenden',
         sentOTP: 'Twee-factor code is verstuurd',
         added: 'Twee-factor authenticatiemethode "{type}" toegevoegd',
         deleted: 'Twee-factor authenticatiemethode "{type}" is verwijderd',
         app: {
            title: 'Authenticatie app',
            subTitle: 'Gebruik een authenticatie-app of browserextensie om tweefactorauthenticatiecodes te krijgen wanneer daarom wordt gevraagd.',
            caption: 'Gebruik een authenticator-app of browserextensie om te scannen.\nAls u niet kunt scannen, voert u in plaats daarvan het onderstaande geheim in:',
            instruction: 'Kopieer onderstaande QR',
            setup: 'Voer de code van de app in:',
            verify: 'Open uw twee-factor authenticatie-app of browserextensie om uw authenticatiecode te bekijken.',
            error: 'Twee-factor codeverificatie mislukt. Probeer het opnieuw.',
         },
         sms: {
            title: 'SMS bericht',
            subTitle: 'Ontvang eenmalige codes die via sms naar uw telefoon worden verzonden om authenticatieverzoeken te voltooien.',
            caption: 'Ontvang authenticatiecodes per sms op uw mobiele telefoon wanneer u zich aanmeldt bij 10FORIT.',
            instruction: 'Uw telefoonnummer',
            verify: 'We hebben uw zojuist een sms gestuurd met uw authenticatiecode.\n Voer de code in het onderstaande veld in om uw identiteit te verifiëren.',
            error: 'Oeps! Uw SMS is niet verzonden. Controleer uw informatie en probeer het opnieuw.',
         },
         email: {
            title: 'Email bericht',
            subTitle: 'Ontvang eenmalige codes die naar uw e-mailadres worden verzonden om authenticatieverzoeken te voltooien.',
            caption: 'Ontvang authenticatiecodes die naar uw e-mailadres worden verzonden wanneer u zich aanmeldt bij 10FORIT.',
            instruction: 'Uw e-mailadres',
            verify: 'We hebben je zojuist een e-mail gestuurd met je authenticatiecode.\n Voer de code in het onderstaande veld in om uw identiteit te verifiëren.',
            error: 'Oeps! Uw e-mail is niet verzonden. Controleer uw informatie en probeer het opnieuw.',
         },
      },
      replacePassword: {
         tab: 'Wachtwoord',
         option: 'Wachtwoord Veranderen',
      },
   },
}
